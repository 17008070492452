/**
 * Check if a form is a Nutshell form
 */
const isNutshellForm = (form) => {
    return form.hasAttribute('data-form-type') && form.id !== '';
};

/**
 * Get the name of a form from its contents
 */
const getFormName = (form) => {
    const heading = form.querySelector('h1, h2, h3, h4, h5, h6');
    if (heading && heading.textContent) {
        return heading.textContent.trim();
    }

    return form.getAttribute('name') || form.id || 'Nutshell Form';
};

/**
 * Push a form view event to the data layer
 */
const pushFormViewEvent = (formId, formName) => {
    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    window.dataLayer.push({
        event: 'form_view',
        form_id: formId,
        form_name: formName,
        form_type: 'Nutshell',
        page_location: window.location.pathname
    });
};

/**
 * Push a form submission event to the data layer
 */
const pushFormSubmitEvent = (formId, formName) => {
    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    window.dataLayer.push({
        event: 'form_submit',
        form_id: formId,
        form_name: formName,
        form_type: 'Nutshell',
        page_location: window.location.pathname
    });
};


/**
* Set up tracking for a specific Nutshell form
*/
const setupFormTracking = (form) => {
    // prvent duplicate tracking
    if (form.hasAttribute('data-ga4-tracking')) {
        return;
    }
    form.setAttribute('data-ga4-tracking', 'true');

    const formName = getFormName(form);

    pushFormViewEvent(form.id, formName);

    let isSubmitting = false;

    form.addEventListener('submit', (event) => {
        if (isSubmitting) return;

        isSubmitting = true;
        pushFormSubmitEvent(form.id, formName);

        setTimeout(() => {
            isSubmitting = false;
        }, 2000);
    });
};

/**
* Check for existing forms on the page
*/
const checkExistingForms = () => {
    document.querySelectorAll('form[data-form-type]').forEach((form) => {
        if (isNutshellForm(form)) setupFormTracking(form);
    });
};

/**
* Observe the DOM for Nutshell form additions
*/
const observeForNutshellForms = () => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                for (let i = 0; i < mutation.addedNodes.length; i++) {
                    const node = mutation.addedNodes[i];
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.tagName === 'FORM' && isNutshellForm(node)) {
                            setupFormTracking(node);
                        } else {
                            const forms = node.querySelectorAll('form');
                            forms.forEach((form) => {
                                if (isNutshellForm(form)) setupFormTracking(form);
                            });
                        }
                    }
                }
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
};

/**
 * Initialise Nutshell Form Tracking
 * Tracks form views and submissions to the data layer for GA4
 */
const nutshellForms = () => {
    checkExistingForms();
    observeForNutshellForms();
};

/**
 * Initialise all form analytics tracking
 */
const initFormAnalytics = () => {
    nutshellForms();
}

export default initFormAnalytics;