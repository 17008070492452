import core from '@gritdigital/ui/core';
import splide from '@gritdigital/ui/packages/splide';
// import masonry from '@gritdigital/ui/packages/masonry';
// import animate from '@gritdigital/ui/packages/animate';
import '@gritdigital/ui/packages/lite-players';
import initAnalytics from './analytics';

// @ts-check

splide.init();
// masonry.init();
// animate.init();
core.init();
initAnalytics();

// Pause YouTube video in modal windows when modal is closed
const dialogs = [...document.querySelectorAll('dialog')];

if (dialogs) {
    dialogs.forEach((dialog) => {
        const videoElement = dialog.querySelector('lite-youtube');

        if (videoElement) {
            const observer = new MutationObserver(() => {
                if (dialog.getAttribute('open') === '') {
                    if (!videoElement.classList.contains('lyt-activated')) {
                        videoElement.click();
                    } else {
                        const iframe = videoElement.querySelector('iframe');
                        iframe.contentWindow.postMessage('{"event":"command", "func":"playVideo", "args":""}', '*');
                    }
                }
            });

            observer.observe(dialog, { attributes: true });

            dialog.addEventListener("close", () => {
                if (videoElement.classList.contains('lyt-activated')) {
                    const iframe = videoElement.querySelector('iframe');
                    iframe.contentWindow.postMessage('{"event":"command", "func":"pauseVideo", "args":""}', '*');
                }
            });
        }
    });
}

// Sticky Header
// class StickyHeader {
//     constructor() {
//         const body = document.querySelector('body');

//         if (window.scrollY > 0) {
//             body.classList.add('is-sticky-header');
//             body.classList.add('sticky-down');
//         }

//         window.addEventListener('scroll', function () {
//             if (window.scrollY > 0) {
//                 body.classList.add('is-sticky-header');
//             } else {
//                 body.classList.remove('is-sticky-header');
//             }

//             if (this.oldScroll > this.scrollY) {
//                 body.classList.add('sticky-up');
//                 body.classList.remove('sticky-down');
//             } else {
//                 body.classList.add('sticky-down');
//                 body.classList.remove('sticky-up');
//             }

//             this.oldScroll = this.scrollY;
//         });
//     }
// }
// new StickyHeader();