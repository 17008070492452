/**
 * Handle click events and track telephone and mailto links
 */
const handleContactClick = (event) => {
    let target = event.target;
    
    while (target && target.tagName !== 'A') {
        target = target.parentElement;
    }
    
    if (!target || !target.hasAttribute('href')) return;
    const href = target.getAttribute('href');
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (href && href.startsWith('tel:')) {
        const phoneNumber = href.replace('tel:', '');
        const linkText = target.textContent || phoneNumber;
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'phone_call',
            phone_number: phoneNumber,
            link_text: linkText.trim(),
            device_type: isMobile ? 'mobile' : 'desktop',
            page_location: window.location.pathname
        });
    } 
    else if (href && href.startsWith('mailto:')) {
        const emailAddress = href.replace('mailto:', '');
        const linkText = target.textContent || emailAddress;
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'email_click',
            email_address: emailAddress,
            link_text: linkText.trim(),
            device_type: isMobile ? 'mobile' : 'desktop',
            page_location: window.location.pathname
        });
    }
};

/**
 * Initialise contact tracking analytics for tel: and mailto: links
 */
const initContactAnalytics = () => {
    document.addEventListener('click', handleContactClick);
};

export default initContactAnalytics;